.inputBox{
    padding: 3.7rem;
   
   }
   .input_row{
    padding-bottom: 1.5rem;
  }
  .label{
    font-size: 1.5rem;
    font-weight: 600;
    display: block;
  }
  .input{
    font-size: 1.3rem;
    line-height: 2rem;
    padding: .6rem 1.3rem;
    width: 100%;
    min-height: 3.2rem;
    border-radius: var(--border-radius-3);
    outline-color: var(--color-black);
    background-color: var(--color-white);
  }
  .btn{
    min-height: 3.2rem;
  }


  