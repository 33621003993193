* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
  }
  
  :root {
    --primary-hue: 41;
    --black-lightness: 15%;
    --dark-lightness: 44%;
    --light-lightness: 94%;
    --white-lightness: 100%;
    --color-primary: hsl(var(--primary-hue), 89%, 41%);
    --color-white: hsl(0, 5%, var(--white-lightness));
    --color-light: hsl(var(--primary-hue), 5%, var(--light-lightness));
    --color-black: hsl(var(--primary-hue), 5%, var(--black-lightness));
    --color-dark: hsl(var(--primary-hae), 5%, var(--dark-lightness));
    /* GENERAL CONTAINER VARIABLES */
    --container-width-lg: 76%;
    --container-width-md: 90%;
    /* BORDER RADIUS VARIABLES */
    --border-radius-1: 1.2rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 0.5rem;
    /*GENERAL TRANSITION VARIABLE */
    --transition: all 500ms ease;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: "Roboto Slab", serif;
    line-height: 1.6;
    font-size: 0.9rem;
  }
  
  /* General Container Class */
  .container {
    width: var(--container-width-lg);
    margin-inline: auto;
    max-width: 1920px;
    padding: 0 1.5rem;
  }
  
  a {
    color: var(--color-black);
    transition: var(--transition);
  }
  
  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    color: var(--color-black);
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.2rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 4.5rem;
  }
  h5 {
    font-size: 0.95rem;
  }
  h6 {
    font-size: 0.8rem;
  }
  
  /* Images */
  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  
  /* Buttons */
  .btn {
    display: inline-block;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 1rem;
    background: var(--color-light);
    color: var(--color-black);
    padding: 0.6rem 2.5rem;
    border-radius: var(--border-radius-2);
    cursor: pointer;
  }
  .btn:hover {
    background-color: transparent;
    border-color: var(--color-light);
    transform: translateY(-0.5rem);
  }
  .btn.white {
    background: var(--color-white);
  }
  .btn.white:hover {
    background: transparent;
    background: var(--color-white);
  }
  .btn.primary {
    background: var(--color-primary);
    color: black;
  }
  .btn.primary:hover {
    background: transparent;
    border-color: var(--color-primary);
    color: black;
  }
  .btn.sm {
    padding: 0.5rem 1.2rem;
  }