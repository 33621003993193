.card {
    background: #C3A7D7;
    padding: 1.5rem 1.9rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-1);
    transition: var(--transition);
    text-transform: uppercase;
    margin: 1.5rem 0;
    position: relative;
    font-size: small;
    min-width: 22rem;
  }
  
  .card:hover {
    background: transparent;
    border-color: var(--color-white);
    transform: translateY(-0.5rem);
  }
  
  .card.light {
    background: var(--color-light);
  }
  
  .card.light:hover {
    background: transparent;
    border-color: var(--color-light);
  }
  
