#button{
    padding-bottom: 1.5rem;
  }
  .row button{
    border-radius: var(--border-radius-3);
    width: 100%;
    height: 3.2rem;
    font-size: 1.3rem;
    color: black;
    background: #C3A7D7;
    background: linear-gradient(90deg, indigo 0%,     #C3A7D7 100%);
    border: 0px;
    cursor: pointer;
    transition: var(--transition);
    margin-top: 1.5rem;
  
  }
  
  .row button:hover{
    opacity: 0.8;
  }