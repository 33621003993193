.container {
      flex: 1;
      background-color: var(--color-white);
}

.detailsContainer {
      padding: 0 1.5rem;
}
.name {
      font-size: 2rem;
      font-weight: "bold"
}
.price {
      font-weight: "bold";
}
.quantityContainer {
      flex-direction: "row";
      align-items: "center";
      margin-bottom: 1.5rem
}
.button {
      border-color: var(--color-white);
      border-radius: var(--border-radius-2);
      height: 3rem;
      width: 3rem;
      justify-content: "center";
      align-items: "center";
}
.quantity {
      font-weight: "bold";
}
.addToCartButton {
      border-color: var(--color-white);
      border-radius: var(--border-radius-2);
      height: 3rem;
      width: "100%";
      justify-content: "center";
      align-items: "center";
}
.addToCartText {
      font-weight: "bold";
}