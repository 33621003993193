.loginForm{
    width: 90%;
    height: 65%;
    margin: 1rem auto;
    background-color: var(--color-white);
    border-radius: var(--border-radius-1);
  }
  .imageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .img{
    width: 25%;
    height: auto;
    box-shadow: 0 .15rem .3rem var(--color-black);
  }