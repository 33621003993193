.row input{
    width: 80%;
    box-sizing: border-box;
      border: none;
    font-size: 1.3rem;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    box-shadow: inset 0px -3px 0px 0px var(--color-black);
    transition: var(--transition);
  }
  
  .row input:focus{
     box-shadow: inset 0px -3px 0px 0px var(--color-dark);
     outline: none;
  }
  
  .row input::-webkit-input-placeholder{
    opacity: 1;
    transition: var(--transition);
  }
  
  .row input:hover::-webkit-input-placeholder,
  .row input:focus::-webkit-input-placeholder{
    opacity: 0;
  }